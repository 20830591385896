import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../img/whiteLogo.svg";
import logoutIcon from "../img/logout.svg";
import menuOpenIcon from "../img/menu.svg";
import menuCloseIcon from "../img/close-burger.svg";
import { useAuth } from "../contexts/AuthContext";
import FeedbackModal from "./FeedbackModal";
import { getUrl } from './apiUrl';

function Header({ toggleSidebar, handleMenuItemClick, isMenuOpen }) {
  const { isAuthenticated, user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const hideBurgerMenu =
    location.pathname === "/login" || location.pathname === "/register";

  const handleNavigation = (path) => {
    navigate(path); 
  };

  const handleFeedbackSubmit = async (feedback) => {
    const userId = user?.id;
    try {
      const apiUrl = getUrl();
      const response = await fetch(`${apiUrl}/api/feedback/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ userId, feedback }),
      });
      if (response.ok) {
        alert("Ваше сообщение отправлено.");
        setIsFeedbackOpen(false);
      } else {
        alert("Ошибка отправки сообщения.");
      }
    } catch (error) {
      console.error("Error sending feedback:", error);
      alert("Ошибка соединения с сервером.");
    }
  };

  return (
    <div className="header-container">
      <header className="App-header">
        <div className="header-left">
          <img src={logo} className="header-logo" alt="logo" />
          <div className="header-text">
            <h1>Территория маляров</h1>
            <p>От мастера мастеру</p>
          </div>
        </div>

        <div className="header-right">
          {user && (
            <div className="user-info">
              <span className="user-name">{user.name}</span>
              <span style={{fontSize: "9px", marginLeft: "10px"}}> v 0.93</span>
            </div>
          )}

          {!hideBurgerMenu && (
            <button className="burger-menu-button" onClick={toggleSidebar}>
              <img
                src={isMenuOpen ? menuCloseIcon : menuOpenIcon}
                alt="Menu Toggle"
              />
            </button>
          )}
        </div>
      </header>

      {isMenuOpen && !hideBurgerMenu && (
        <>
          <div className="blur-background" onClick={toggleSidebar}></div>

          <nav className="burger-menu">
            <div className="burger-menu-content">
              <ul>
                <li className="sidebar-li">
                  <button
                    className="sidebar-a"
                    onClick={() => handleNavigation("/new-calculation")}
                  >
                    <svg
                      className="menu-icon"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.33333 18.3332H11.6667C15.8333 18.3332 17.5 16.6665 17.5 12.4998V7.49984C17.5 3.33317 15.8333 1.6665 11.6667 1.6665H8.33333C4.16667 1.6665 2.5 3.33317 2.5 7.49984V12.4998C2.5 16.6665 4.16667 18.3332 8.33333 18.3332Z"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.75 6.31689V7.15023C13.75 7.83356 13.1917 8.40023 12.5 8.40023H7.5C6.81667 8.40023 6.25 7.84189 6.25 7.15023V6.31689C6.25 5.63356 6.80833 5.06689 7.5 5.06689H12.5C13.1917 5.06689 13.75 5.62523 13.75 6.31689Z"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.7801 11.6667H6.78973"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.99604 11.6667H10.0057"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.212 11.6667H13.2216"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.7801 14.5832H6.78973"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.99604 14.5832H10.0057"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.212 14.5832H13.2216"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Расчет
                  </button>
                </li>
                <li className="sidebar-li">
                  <button
                    className="sidebar-a"
                    onClick={() => handleNavigation("/my-prices")}
                  >
                    <svg
                      className="menu-icon"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 18.9582C6.66671 18.9582 3.95837 16.5665 3.95837 13.6248V10.5415C3.95837 10.1998 4.24171 9.9165 4.58337 9.9165C4.92504 9.9165 5.20837 10.1998 5.20837 10.5415C5.20837 12.7248 7.26671 14.3748 10 14.3748C12.7334 14.3748 14.7917 12.7248 14.7917 10.5415C14.7917 10.1998 15.075 9.9165 15.4167 9.9165C15.7584 9.9165 16.0417 10.1998 16.0417 10.5415V13.6248C16.0417 16.5665 13.3334 18.9582 10 18.9582ZM5.20837 13.7165C5.26671 15.9248 7.39171 17.7082 10 17.7082C12.6084 17.7082 14.7334 15.9248 14.7917 13.7165C13.7084 14.8915 11.9917 15.6248 10 15.6248C8.00837 15.6248 6.30004 14.8915 5.20837 13.7165Z"
                        fill="#F2F2F7"
                      />
                      <path
                        d="M10 11.4582C7.70004 11.4582 5.63336 10.4248 4.62503 8.75817C4.1917 8.04984 3.95837 7.22484 3.95837 6.37484C3.95837 4.9415 4.60004 3.5915 5.75838 2.57484C6.89171 1.58317 8.40004 1.0415 10 1.0415C11.6 1.0415 13.1 1.58317 14.2417 2.56651C15.4 3.59151 16.0417 4.9415 16.0417 6.37484C16.0417 7.22484 15.8084 8.0415 15.3751 8.75817C14.3667 10.4248 12.3 11.4582 10 11.4582ZM10 2.2915C8.70004 2.2915 7.48339 2.72484 6.57505 3.52484C5.69172 4.2915 5.20837 5.30817 5.20837 6.37484C5.20837 6.99984 5.37503 7.58317 5.6917 8.10817C6.48336 9.40817 8.13337 10.2082 10 10.2082C11.8667 10.2082 13.5167 9.39984 14.3084 8.10817C14.6334 7.58317 14.7917 6.99984 14.7917 6.37484C14.7917 5.30817 14.3084 4.2915 13.4167 3.50817C12.5084 2.72484 11.3 2.2915 10 2.2915Z"
                        fill="#F2F2F7"
                      />
                      <path
                        d="M10 15.6248C6.55837 15.6248 3.95837 13.4415 3.95837 10.5415V6.37484C3.95837 3.43317 6.66671 1.0415 10 1.0415C11.6 1.0415 13.1 1.58317 14.2417 2.56651C15.4 3.59151 16.0417 4.9415 16.0417 6.37484V10.5415C16.0417 13.4415 13.4417 15.6248 10 15.6248ZM10 2.2915C7.35837 2.2915 5.20837 4.12484 5.20837 6.37484V10.5415C5.20837 12.7248 7.26671 14.3748 10 14.3748C12.7334 14.3748 14.7917 12.7248 14.7917 10.5415V6.37484C14.7917 5.30817 14.3084 4.2915 13.4167 3.50817C12.5084 2.72484 11.3 2.2915 10 2.2915Z"
                        fill="#F2F2F7"
                      />
                    </svg>
                    Мои цены
                  </button>
                </li>
              </ul>

              <div className="lower-sidebar">
                <div className="support-section">
                  <h2>Остались вопросы?</h2>
                  <p>
                    Обратитесь в тех. поддержку, сообщите об ошибке или внесите предложение по функционалу программы
                  </p>
                  <button
                    className="secondary-button sidebar-btn"
                    onClick={() => setIsFeedbackOpen(true)} // Open feedback modal
                  >
                    Обратная связь
                  </button>
                </div>

                {isAuthenticated && (
                  <button
                    className="logout-button sidebar-logout"
                    onClick={handleLogout}
                  >
                    <img src={logoutIcon} alt="logOut" />
                    <label>Выйти из аккаунта</label>
                  </button>
                )}
              </div>
            </div>
          </nav>
        </>
      )}

      {/* Feedback Modal */}
      <FeedbackModal
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)} // Close the feedback modal
        onSubmit={handleFeedbackSubmit} // Submit feedback
      />
    </div>
  );
}

export default Header;
