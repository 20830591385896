import React from "react";
import squareMeterIcon from "../img/m2.svg";
import linearMeterIcon from "../img/pm.svg";

function CalculatorStep({
  wallArea,
  setWallArea,
  tapeLength,
  setTapeLength,
  plasterboardArea,
  setPlasterboardArea,
  selectedOption, // Выбранная опция передается из родительского компонента
  setSelectedOption, // Функция для обновления выбранной опции
}) {
  // Обрабатываем изменение выбора радио-кнопки
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value); // Обновляем состояние с выбранной опцией
  };

  return (
    <div className="calculator-container">
      <div className="calculator-page">
        <div className="calculator-card" style={{ position: "relative" }}>
          <div className="calculator-description">
            <h1>Размеры</h1>
            <p>
              Заполните площадь поверхности и укажите основание поверхности
            </p>
          </div>
          <div className="calculator-input-group">
            <div className="calculator-input-field">
              <label>Площадь поверхности</label>
              <div className="input-wrapper">
                <input
                  type="number"
                  value={wallArea === 0 ? "" : wallArea}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNaN(Number(value))) {
                      setWallArea(Number(value));
                    }
                  }}
                  placeholder="Площадь стен"
                />
                <img src={squareMeterIcon} alt="м2" className="unit-icon" />
              </div>
              <div className="radio-btn-wrapper">
                <p className="radio-btn-p">Основание поверхности стен: </p>
                <div className="radio-buttons">
                  <input
                    type="radio"
                    id="plaster"
                    name="option"
                    value="plaster"
                    checked={selectedOption === "plaster"}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="plaster">Штукатурка</label>

                  <input
                    type="radio"
                    id="plasterboard"
                    name="option"
                    value="plasterboard"
                    checked={selectedOption === "plasterboard"}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="plasterboard">Гипсокартон</label>
                </div>
              </div>
            </div>
            <div className="calculator-input-field"></div>
            {/* <div className="calculator-input-field">
              <label>Расчет ленты</label>
              <div className="input-wrapper">
                <input
                  type="number"
                  value={tapeLength}
                  onChange={(e) => setTapeLength(Number(e.target.value))}
                  placeholder="Лента"
                />
                <img src={linearMeterIcon} alt="п.м" className="unit-icon" />
              </div>
            </div> */}

            {/* <div className="calculator-input-field">
              <label>Площадь потолка</label>
              <div className="input-wrapper">
                <input
                  type="number"
                  value={plasterboardArea}
                  onChange={(e) => setPlasterboardArea(Number(e.target.value))}
                  placeholder="Площадь потолка"
                />
                <img src={squareMeterIcon} alt="м2" className="unit-icon" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalculatorStep;
