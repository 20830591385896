import React, { useState, useMemo, useCallback, useEffect } from "react";
import "../style/App.css";
import {
  smitMaterialsBase,
  smitPlasterboardPLUK,
  smitPlasterboardUK,
  smitWallMaterial,
  smitWallPlasteboard,
  // smitPlasterboardBase,
} from "../data/smitMaterialData";
import Header from "../components/Header";
import Sidebar from "../components/SideBar";
import { getUrl } from "../components/apiUrl";
import { knaufPlasterboard, knaufWallMaterial } from "../data/knaufMaterialData";

function MyPrices() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [sortOrder, setSortOrder] = useState("default");
  const [editingItemId, setEditingItemId] = useState(null);
  const [editingPrice, setEditingPrice] = useState("");
  const [showMyPrices, setShowMyPrices] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showOnlyUnfilledPrices, setShowOnlyUnfilledPrices] = useState(false);

  const manufacturers = [
    { name: "Knauf", label: "Knauf", isLocked: false },
    { name: "Smit", label: "СМиТ", isLocked: false },
  ];

  const getDataByManufacturer = () => {
    if (selectedManufacturer === "Knauf") {
      return [
        ...knaufPlasterboard.map((item) => ({ ...item})),
        ...knaufWallMaterial.map((item) => ({ ...item})),
      ].filter((item, index, self) => 
        index === self.findIndex((t) => t.id === item.id)
      );
    } else {
      return [
        ...smitPlasterboardUK.map((item) => ({ ...item})),
        ...smitPlasterboardPLUK.map((item) => ({ ...item})),
        ...smitWallMaterial.map((item) => ({ ...item})),
      ].filter((item, index, self) => 
        index === self.findIndex((t) => t.id === item.id)
      );
    }
  };

  const [prices, setPrices] = useState({});

  const filterAndSortItems = (items) => {
    return items
      .filter((item) => {
        const matchesSearch = item.name
          .toLowerCase()
          .startsWith(searchTerm.toLowerCase());
        const hasPrice = prices[item.id] !== undefined;
        return matchesSearch && (!showOnlyUnfilledPrices || !hasPrice);
      })
      .sort((a, b) => {
        if (sortOrder === "asc") {
          return (prices[a.id] || 0) - (prices[b.id] || 0);
        } else if (sortOrder === "desc") {
          return (prices[b.id] || 0) - (prices[a.id] || 0);
        }
        return 0;
      });
  };

  const filteredItems = useMemo(
    () => filterAndSortItems(getDataByManufacturer()),
    [
      selectedManufacturer,
      searchTerm,
      sortOrder,
      prices,
      showOnlyUnfilledPrices,
    ]
  );

  const handlePriceChange = (event) => {
    const value = parseFloat(event.target.value);
    if (value >= 0 || event.target.value === "") {
      setEditingPrice(event.target.value);
    }
  };

  const handleConfirm = () => {
    if (editingItemId) {
      const payload = {
        userId: JSON.parse(localStorage.getItem("user")).id,
        manufacturer: selectedManufacturer,
        itemId: editingItemId,
        price: parseFloat(editingPrice),
      };

      fetch(`${getUrl()}/api/prices/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Ошибка HTTP: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setPrices((prevPrices) => ({
            ...prevPrices,
            [editingItemId]: parseFloat(editingPrice),
          }));
          setEditingItemId(null);
          setEditingPrice("");
        })
        .catch((error) => {
          console.error("Error updating price:", error);
        });
    }
  };

  const loadPrices = useCallback(() => {
    const userId = JSON.parse(localStorage.getItem("user")).id;
    if (!selectedManufacturer || !userId) return;

    fetch(
      `${getUrl()}/api/prices?manufacturer=${selectedManufacturer}&userId=${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка HTTP: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const updatedPrices = data.reduce((acc, item) => {
          acc[item.itemId] = item.price;
          return acc;
        }, {});
        setPrices(updatedPrices);
      })
      .catch((error) => console.error("Ошибка загрузки цен:", error));
  }, [selectedManufacturer]);

  useEffect(() => {
    loadPrices();
  }, [loadPrices]);

  const handleEditClick = (id) => {
    setEditingItemId(id);
    setEditingPrice(prices[id] || "");
  };

  const handleCancel = () => {
    setEditingItemId(null);
    setEditingPrice("");
  };

  const toggleSidebar = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = (menuItem) => {
    if (menuItem === "Мои цены") {
      setShowMyPrices(true);
    } else {
      setShowMyPrices(false);
    }
    setIsMenuOpen(false);
  };

  const handleToggleChange = () => {
    setShowOnlyUnfilledPrices((prev) => !prev);
  };

  return (
    <div className="container">
      <Header
        handleMenuItemClick={handleMenuItemClick}
        toggleSidebar={toggleSidebar}
        isMenuOpen={isMenuOpen}
      />
      <Sidebar />
      <div className="main-content my-prices-page">
        <header className="main-header">
          <h1>Мои цены</h1>
          <p>Заполните информацию о ценах для расчета сметы материалов</p>
        </header>

        <div className="filters">
          <input
            type="text"
            className="search-input"
            placeholder="Поиск"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            disabled={!selectedManufacturer}
          />
          <select
            className="manufacturer-select"
            value={selectedManufacturer}
            onChange={(e) => setSelectedManufacturer(e.target.value)}
          >
            <option value="">Выбрать производителя</option>
            {manufacturers.map((manufacturer) => (
              <option
                key={manufacturer.name}
                value={manufacturer.name}
                disabled={manufacturer.isLocked}
              >
                {manufacturer.label}
              </option>
            ))}
          </select>
        </div>

        {selectedManufacturer && (
          <div className="show-unfilled-toggle">
            <label className="switch">
              <input
                type="checkbox"
                checked={showOnlyUnfilledPrices}
                onChange={handleToggleChange}
              />
              <span className="slider round"></span>
            </label>
            <span className="toggle-label-text">Показать позиции без цен</span>
          </div>
        )}

        {selectedManufacturer ? (
          <div className="my-price-results results-container custom-scrollbar">
            {filteredItems.length > 0 ? (
              filteredItems.map((item) => (
                <div key={item.id} className="supplies-item">
                  <img src={item.icon} alt="" className="supplies-icon" />
                  <div className="supplies-detail my-prices">
                    <h3 className="detail-item-name">{item.name}</h3>
                    <p className="detail-packaging">{item.packaging || ""} {item.unit}</p>
                  </div>
                  {editingItemId === item.id ? (
                    <div className="price-input-container">
                      <input
                        type="number"
                        className="price-input"
                        placeholder="Введите цену"
                        value={editingPrice || ""}
                        min={0}
                        onChange={handlePriceChange}
                      />
                      <button
                        className="my-prices-icon-button my-prices-confirm-button"
                        onClick={handleConfirm}
                      >
                        <svg
                          width="24"
                          height="24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.6531 17C10.3929 17 10.1458 16.8884 9.96373 16.6931L6.28289 12.7447C5.9057 12.3401 5.9057 11.6704 6.28289 11.2658C6.66008 10.8612 7.28439 10.8612 7.66158 11.2658L10.6531 14.4747L17.3384 7.30345C17.7156 6.89885 18.3399 6.89885 18.7171 7.30345C19.0943 7.70806 19.0943 8.37775 18.7171 8.78235L11.3424 16.6931C11.1603 16.8884 10.9132 17 10.6531 17Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                      <button
                        className="my-prices-icon-button my-prices-cancel-button"
                        onClick={handleCancel}
                      >
                        <svg
                          width="24"
                          height="24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.04472 17C7.77918 17 7.51363 16.9022 7.30398 16.6926C6.89867 16.2875 6.89867 15.6168 7.30398 15.2117L15.2145 7.30388C15.6198 6.89871 16.2907 6.89871 16.696 7.30388C17.1013 7.70905 17.1013 8.37967 16.696 8.78484L8.78546 16.6926C8.5898 16.9022 8.31027 17 8.04472 17Z"
                            fill="white"
                          />
                          <path
                            d="M15.9553 17C15.6897 17 15.4242 16.9022 15.2145 16.6926L7.30398 8.78484C6.89867 8.37967 6.89867 7.70905 7.30398 7.30388C7.70929 6.89871 8.38015 6.89871 8.78546 7.30388L16.696 15.2117C17.1013 15.6168 17.1013 16.2875 16.696 16.6926C16.4864 16.9022 16.2208 17 15.9553 17Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  ) : (
                    <div className="price-result-container">
                      <div className="price-result">
                        <span className="price-text">Моя цена </span>
                        <span className="price-number">
                          {prices[item.id] !== undefined
                            ? `${prices[item.id]} ₽`
                            : "0"}
                        </span>
                      </div>
                      <button
                        className="edit-price"
                        onClick={() => handleEditClick(item.id)}
                      >
                        <svg
                          width="24"
                          height="24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z"
                            fill="white"
                          />
                          <path
                            d="M8.50032 17.6901C7.89032 17.6901 7.33032 17.4701 6.92032 17.0701C6.43032 16.5801 6.22032 15.8701 6.33032 15.1201L6.76032 12.1101C6.84032 11.5301 7.22032 10.7801 7.63032 10.3701L15.5103 2.49006C17.5003 0.500059 19.5203 0.500059 21.5103 2.49006C22.6003 3.58006 23.0903 4.69006 22.9903 5.80006C22.9003 6.70006 22.4203 7.58006 21.5103 8.48006L13.6303 16.3601C13.2203 16.7701 12.4703 17.1501 11.8903 17.2301L8.88032 17.6601C8.75032 17.6901 8.62032 17.6901 8.50032 17.6901ZM16.5703 3.55006L8.69032 11.4301C8.50032 11.6201 8.28032 12.0601 8.24032 12.3201L7.81032 15.3301C7.77032 15.6201 7.83032 15.8601 7.98032 16.0101C8.13032 16.1601 8.37032 16.2201 8.66032 16.1801L11.6703 15.7501C11.9303 15.7101 12.3803 15.4901 12.5603 15.3001L20.4403 7.42006C21.0903 6.77006 21.4303 6.19006 21.4803 5.65006C21.5403 5.00006 21.2003 4.31006 20.4403 3.54006C18.8403 1.94006 17.7403 2.39006 16.5703 3.55006Z"
                            fill="white"
                          />
                          <path
                            d="M19.8496 9.82978C19.7796 9.82978 19.7096 9.81978 19.6496 9.79978C17.0196 9.05978 14.9296 6.96978 14.1896 4.33978C14.0796 3.93978 14.3096 3.52978 14.7096 3.40978C15.1096 3.29978 15.5196 3.52978 15.6296 3.92978C16.2296 6.05978 17.9196 7.74978 20.0496 8.34978C20.4496 8.45978 20.6796 8.87978 20.5696 9.27978C20.4796 9.61978 20.1796 9.82978 19.8496 9.82978Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>Нет данных для отображения</p>
            )}
          </div>
        ) : (
          <p className="price-result-p">
            Выберите производителя, чтобы увидеть список позиций
          </p>
        )}
      </div>
    </div>
  );
}

export default MyPrices;
