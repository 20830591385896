import React, { useState } from "react";

const FeedbackModal = ({ isOpen, onClose, onSubmit, userId }) => {
  const [feedback, setFeedback] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(""); // State for selected category

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ feedback, selectedCategory }); // Pass both feedback and selected category to onSubmit
    setFeedback("");
    setSelectedCategory(""); // Clear category after submission
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2 className="modal-h2">Новый запрос</h2>
        <form onSubmit={handleSubmit}>
          {/* Dropdown for selecting category */}
          <select
            className="manufacturer-select modal-dropdown"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            required
          >
            <option value="" disabled>
              Выберите категорию
            </option>
            <option value="bug">Сообщить об ошибке</option>
            <option value="feature">Предложить функцию</option>
            <option value="other">Другое</option>
          </select>

          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Введите текст"
            required
          />
          <button className="modal-btn" type="submit">Отправить</button>
        </form>
      </div>
    </div>
  );
};

export default FeedbackModal;
