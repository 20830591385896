import React from "react";
import "../style/App.css";

function SuppliesItem({
  name,
  packaging,
  consumption,
  quantity,
  units,
  unit,
  icon,
  isPlasterboard,
}) {
  return (
    <div className="supplies-item">
      <div className="supplies-icon">
        <img src={icon} alt={name} className="supplies-icon-img" />
      </div>
      <div className="supplies-details">
        {isPlasterboard ? (
          <>
            <div className="plaster-item">
              <span className="detail-value detail-item-name plaster-n">
                {name}
              </span>
            </div>
            <div className="plaster-item">
              <span className="detail-value quantity-value">
                {quantity} упк.
              </span>
              <span className="quantity-value-mobile">{quantity} упк.</span>
            </div>
          </>
        ) : (
          <>
            <div className="supplies-detail">
              <span className="detail-item-name">
                {name}
              </span>
              <span className="detail-value-light"> {packaging} {unit}</span>
            </div>
            <div className="supplies-detail-right">
              <div className="supplies-detail">
                <span className="detail-title">Расход на м2:</span>
                <span className="detail-value">{consumption} {unit}</span>
              </div>
              <div className="supplies-detail supplies-quant">
                <span className="detail-title">Кол-во упаковок:</span>
                <span className="detail-value quantity-value">
                  {units} упк.
                </span>
                <span className="detail-value quantity-value-mobile">
                  {units} упк.
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SuppliesItem;
