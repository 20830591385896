import React, { useState } from "react";
import "../style/App.css";
import eyeIcon from "../img/eye-open.svg";
import eyeClosedIcon from "../img/eye-closed.svg";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import { useAuth } from "../contexts/AuthContext";
import FrameFront from "../img/login-img-dark.png";
import logo from "../img/whiteLogo.svg";

function Login() {
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    setProgress(0);

    try {
      const response = await fetch(
        "https://dp-calculating-api-8d4e954ecc53.herokuapp.com/api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        const responseData = await response.json();
        setError(responseData.msg || "Ошибка авторизации");
        setLoading(false);
      } else {
        const responseData = await response.json();
        login(responseData.user, responseData.token);
        setProgress(100);
        navigate("/new-calculation");
      }
    } catch (err) {
      setError("Ошибка сети");
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className="page-container">
      {loading ? (
        <LoadingScreen progress={progress} setProgress={setProgress} />
      ) : (
        <>
          <div className="left-column">
            <motion.div
              className="login-register-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <h2>Добро пожаловать!</h2>
              <p>Войдите в свой аккаунт</p>
              <form onSubmit={handleSubmit}>
                <div className="input-field">
                  <input
                    type="email"
                    name="email"
                    placeholder="Введите e-mail"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="input-field password-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Введите пароль"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <img
                    src={showPassword ? eyeClosedIcon : eyeIcon}
                    alt="Показать пароль"
                    className="eye-icon"
                    onClick={togglePasswordVisibility}
                  />
                </div>

                {error && <p className="error-message">{error}</p>}
                <div className="button-group">
                  <button
                    type="submit"
                    className="primary-button glow"
                    disabled={loading}
                  >
                    {loading ? "Вход..." : "Войти в аккаунт"}
                  </button>
                  <button
                    type="button"
                    className="secondary-button"
                    onClick={() => navigate("/register")}
                  >
                    Нет аккаунта?
                  </button>
                </div>
              </form>
            </motion.div>
          </div>
          <div className="right-column">
            <div className="logo-login">
              <img src={logo} className="header-logo" alt="logo" />
              <div className="header-text">
                <h1>Территория маляров</h1>
                <p>От мастера мастеру</p>
              </div>
            </div>
            <div className="card-login">
              <img
                className="card-login-img"
                src={FrameFront}
                alt="Frame Front"
              ></img>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Login;
