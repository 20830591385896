// Это для стен с покрытием Штукатурка
export const smitWallMaterial = [
  {
    id: "10006",
    name: "Q1 Грунтовка BP-025",
    packaging: 5,
    consumptionPerSquareMeter: 0.041,
    unit: "кг",
    icon: "products/bp205.png",
    category: "material",
    formula: "x = 0.041 * (m2)"
  },
  {
    id: "10012",
    name: "Q1 Шпаклевка CE-78 / AirlessGarnisant",
    packaging: 25,
    consumptionPerSquareMeter: 1.6,
    unit: "кг",
    icon: "products/СЕ 78 белая крышка.jpeg",
    category: "material",
    formula: "x = 1.6 * (m2)"
  },
  {
    id: "10012",
    name: "Q2 Шпаклевка CE-78 / AirlessGarnisant",
    packaging: 25,
    consumptionPerSquareMeter: 1.12,
    unit: "кг",
    icon: "products/СЕ 78 белая крышка.jpeg",
    category: "material",
    formula: "x = 1.12 * (m2)"
  },
  {
    id: "100104",
    name: "Q3 Грунтовка BP-025 (К) 1:3",
    packaging: 5,
    consumptionPerSquareMeter: 0.041,
    unit: "кг",
    icon: "products/Базис Праймер 025.jpeg",
    category: "material",
    formula: "x = 0.041 * (m2)"
  },
  {    
    id: "10322",
    name: "Q3 Клей (КОЛЬ ТДВ)",
    packaging: 10,
    consumptionPerSquareMeter: 0.125,
    unit: "кг",
    icon: "products/kley-500x500.jpg",
    category: "material",
    formula: "x = 0.125 * (m2)"
  },
  {    
    id: "10422",
    name: "Q3 Стеклохолст W40 / W50",
    packaging: 50,
    consumptionPerSquareMeter: 1,
    unit: "м",
    icon: "products/Стеклохолст V50.jpg",
    category: "material",
    formula: "x = 1 * (m2)"
  },
  {
    id: "10022",
    name: "Q3 Финишная шпаклевка CE-78 / AirlessClassic",
    packaging: 25,
    consumptionPerSquareMeter: 1.6,
    unit: "кг",
    icon: "products/АИРЛЕСС.jpg",
    category: "material",
    formula: "x = 1.6 * (m2)"
  },
  {    
    id: "10522",
    name: "Q3 СУ-КУШ",
    packaging: 22,
    consumptionPerSquareMeter: 0.314,
    unit: "кг",
    icon: "products/СУ-КУШ белая крышка.jpg",
    category: "material",
    formula: "x = 0.314 * (m2)"
  },
  {
    id: "10006",
    name: "Q4 Грунтовка BP-025",
    packaging: 5,
    consumptionPerSquareMeter: 0.041,
    unit: "кг",
    icon: "products/bp205.png",
    category: "material",
    formula: "x = 0.041 * (m2)"
  },
  {
    id: "10466",
    name: "Q4 ПП 020",
    packaging: 5,
    consumptionPerSquareMeter: 0.125,
    unit: "л",
    icon: "products/pp020.webp",
    category: "material",
    formula: "x = 0.125 * (m2)"
  },
  {
    id: "10476",
    name: "Q4 ПП 150",
    packaging: 5,
    consumptionPerSquareMeter: 0.0625,
    unit: "л",
    icon: "products/pp150.jpg",
    category: "material",
    formula: "x = 0.0625 * (m2)"
  },
  {
    id: "10022",
    name: "Q4 Финишная шпаклевка CE-78 / AirlessClassic",
    packaging: 25,
    consumptionPerSquareMeter: 1.12,
    unit: "кг",
    icon: "products/АИРЛЕСС.jpg",
    category: "material",
    formula: "x = 1.12 * (m2)"
  },
  {
    id: "10476",
    name: "Q4 Coat (ВЕЛЮР, черная крышка)",
    packaging: 10,
    consumptionPerSquareMeter: 0.25,
    unit: "л",
    icon: "products/coat_1.jpg",
    category: "material",
    formula: "x = 0.25 * (m2)"
  },
  {
    id: "10477",
    name: "Q4 Coat (Акустик Кофорт 'Бархат')",
    packaging: 9,
    consumptionPerSquareMeter: 0.25,
    unit: "л",
    icon: "products/coat_2.jpg",
    category: "material",
    formula: "x = 0.25 * (m2)"
  },
];

export const smitPlasterboardUK = [
  {
    id: "10006",
    name: "Q1 Грунтовка BP-025 (К) 1:3",
    packaging: 5,
    consumptionPerSquareMeter: 0.041,
    unit: "кг",
    icon: "products/bp205.png",
    category: "material",
    formula: "x = 0.041 * (m2)"
  },
  {
    id: "10014",
    name: "Q1 Шпаклевка для приклеивания лент СЕ-78 (серая крышка)",
    packaging: 8,
    consumptionPerSquareMeter: 0.177,
    unit: "кг",
    icon: "products/СЕ 78 серая крышка.jpg",
    category: "material",
    formula: "x = 0.177 * (m2)"
  },
  {
    id: "10101",
    name: "Q1 Лента корнер",
    packaging: 150,
    consumptionPerSquareMeter: 1,
    unit: "м",
    icon: "products/stritflex.png",
    category: "tape",
    formula: "x = (m2)"
  },
  {
    id: "10015",
    name: "Q1 Шпаклевка гипсополимерная GP-89",
    packaging: 5,
    consumptionPerSquareMeter: 0.625,
    unit: "кг",
    icon: "products/GP-89.jpg",
    category: "material",
    formula: "x = 0.625 * (m2)"
  },
  {
    id: "100224",
    name: "Q2 CE-78 Гидро",
    packaging: 18,
    consumptionPerSquareMeter: 1.6,
    unit: "кг",
    icon: "products/ce78_hydro.webp",
    category: "material",
    formula: "x = (m2 * 0.8) * 1.6"
  },
  {
    id: "10006",
    name: "Q3 Грунтовка BP-025 (К) 1:3",
    packaging: 5,
    consumptionPerSquareMeter: 0.041,
    unit: "кг",
    icon: "products/bp205.png",
    category: "material",
    formula: "x = 0.041 * (m2)"
  },
  {    
    id: "10322",
    name: "Q3 Клей (КОЛЬ ТДВ)",
    packaging: 10,
    consumptionPerSquareMeter: 0.125,
    unit: "кг",
    icon: "products/kley-500x500.jpg",
    category: "material",
    formula: "x = 0.125 * (m2)"
  },
  {    
    id: "10422",
    name: "Q3 Стеклохолст W40 / W50",
    packaging: 50,
    consumptionPerSquareMeter: 1,
    unit: "м",
    icon: "products/Стеклохолст V50.jpg",
    category: "material",
    formula: "x = 1 * (m2)"
  },
  {
    id: "10022",
    name: "Q3 Финишная шпаклевка CE-78 / AirlessClassic",
    packaging: 25,
    consumptionPerSquareMeter: 1.6,
    unit: "кг",
    icon: "products/АИРЛЕСС.jpg",
    category: "material",
    formula: "x = 1.6 * (m2)"
  },
  {    
    id: "10522",
    name: "Q3 СУ-КУШ",
    packaging: 22,
    consumptionPerSquareMeter: 0.314,
    unit: "кг",
    icon: "products/СУ-КУШ белая крышка.jpg",
    category: "material",
    formula: "x = 0.314 * (m2)"
  },
  {
    id: "10006",
    name: "Q4 Грунтовка BP-025 (К) 1:3",
    packaging: 5,
    consumptionPerSquareMeter: 0.041,
    unit: "кг",
    icon: "products/bp205.png",
    category: "material",
    formula: "x = 0.041 * (m2)"
  },
  {
    id: "10022",
    name: "Q4 Финишная шпаклевка CE-78 / AirlessClassic",
    packaging: 25,
    consumptionPerSquareMeter: 1.12,
    unit: "кг",
    icon: "products/АИРЛЕСС.jpg",
    category: "material",
    formula: "x = 1.12 * (m2)"
  },
  {
    id: "10476",
    name: "Q4 Coat (ВЕЛЮР, черная крышка)",
    packaging: 10,
    consumptionPerSquareMeter: 0.25,
    unit: "л",
    icon: "products/coat_1.jpg",
    category: "material",
    formula: "x = 0.25 * (m2)"
  },
  {
    id: "10477",
    name: "Q4 Coat (Акустик Кофорт 'Бархат')",
    packaging: 9,
    consumptionPerSquareMeter: 0.25,
    unit: "л",
    icon: "products/coat_2.jpg",
    category: "material",
    formula: "x = 0.25 * (m2)"
  },
]

// Это для покрытия Гипсокартон + шов ПЛУК
export const smitPlasterboardPLUK = [
  {
    id: "10006",
    name: "Q1 Грунтовка BP-025 (К) 1:3",
    packaging: 5,
    consumptionPerSquareMeter: 0.041,
    unit: "кг",
    icon: "products/bp205.png",
    category: "material",
    formula: "x = 0.041 * (m2)"
  },
  {
    id: "10015",
    name: "Q1 Шпаклевка гипсополимерная GP-89",
    packaging: 5,
    consumptionPerSquareMeter: 0.625,
    unit: "кг",
    icon: "products/GP-89.jpg",
    category: "material",
    formula: "x = 0.625 * (m2)"
  },
  {
    id: "10101",
    name: "Q1 Лента корнер",
    packaging: 150,
    consumptionPerSquareMeter: 1,
    unit: "м",
    icon: "products/stritflex.png",
    category: "tape",
    formula: "x = (m2)"
  },
  {
    id: "10015",
    name: "Q1 Шпаклевка гипсополимерная GP-89",
    packaging: 5,
    consumptionPerSquareMeter: 0.625,
    unit: "кг",
    icon: "products/GP-89.jpg",
    category: "material",
    formula: "x = 0.625 * (m2)"
  },
  {
    id: "100224",
    name: "Q2 CE-78 Гидро",
    packaging: 18,
    consumptionPerSquareMeter: 1.6,
    unit: "кг",
    icon: "products/ce78_hydro.webp",
    category: "material",
    formula: "x = (m2 * 0.8) * 1.6"
  },
  {
    id: "100104",
    name: "Q3 Грунтовка BP-025 (К) 1:3",
    packaging: 5,
    consumptionPerSquareMeter: 0.041,
    unit: "кг",
    icon: "products/Базис Праймер 025.jpeg",
    category: "material",
    formula: "x = 0.041 * (m2)"
  },
  {    
    id: "10322",
    name: "Q3 Клей (КОЛЬ ТДВ)",
    packaging: 10,
    consumptionPerSquareMeter: 0.125,
    unit: "кг",
    icon: "products/kley-500x500.jpg",
    category: "material",
    formula: "x = 0.125 * (m2)"
  },
  {    
    id: "10422",
    name: "Q3 Стеклохолст W40 / W50",
    packaging: 50,
    consumptionPerSquareMeter: 1,
    unit: "м",
    icon: "products/Стеклохолст V50.jpg",
    category: "material",
    formula: "x = 1 * (m2)"
  },
  {
    id: "10022",
    name: "Q3 Финишная шпаклевка CE-78 / AirlessClassic",
    packaging: 25,
    consumptionPerSquareMeter: 1.6,
    unit: "кг",
    icon: "products/АИРЛЕСС.jpg",
    category: "material",
    formula: "x = 1.6 * (m2)"
  },
  {    
    id: "10522",
    name: "Q3 СУ-КУШ",
    packaging: 22,
    consumptionPerSquareMeter: 0.314,
    unit: "кг",
    icon: "products/СУ-КУШ белая крышка.jpg",
    category: "material",
    formula: "x = 0.314 * (m2)"
  },
  {
    id: "10006",
    name: "Q4 Грунтовка BP-025 (К) 1:3",
    packaging: 5,
    consumptionPerSquareMeter: 0.041,
    unit: "кг",
    icon: "products/bp205.png",
    category: "material",
    formula: "x = 0.041 * (m2)"
  },
  {
    id: "10022",
    name: "Q4 Финишная шпаклевка CE-78 / AirlessClassic",
    packaging: 25,
    consumptionPerSquareMeter: 1.12,
    unit: "кг",
    icon: "products/АИРЛЕСС.jpg",
    category: "material",
    formula: "x = 1.12 * (m2)"
  },
  {
    id: "10476",
    name: "Q4 Coat (ВЕЛЮР, черная крышка)",
    packaging: 10,
    consumptionPerSquareMeter: 0.25,
    unit: "л",
    icon: "products/coat_1.jpg",
    category: "material",
    formula: "x = 0.25 * (m2)"
  },
  {
    id: "10477",
    name: "Q4 Coat (Акустик Кофорт 'Бархат')",
    packaging: 9,
    consumptionPerSquareMeter: 0.25,
    unit: "л",
    icon: "products/coat_2.jpg",
    category: "material",
    formula: "x = 0.25 * (m2)"
  },
]
