import React, { useState, useEffect } from "react";
import knaufLogo from "../img/Knauf.png";
import smitLogo from "../img/Smit.png";
import SelectableCard from "./SelectableCard";

const ManufacturerStep = ({ handleManufacturerSelection }) => {
  const [selectedManufacturer, setSelectedManufacturer] = useState("");

  useEffect(() => {
    if (selectedManufacturer) {
      handleManufacturerSelection(selectedManufacturer);
    }
  }, [selectedManufacturer, handleManufacturerSelection]);

  const handleSelect = (manufacturer) => {
    setSelectedManufacturer(manufacturer);
  };

  const manufacturers = [
    { name: "Knauf", label: "Knauf", isLocked: false, logo: knaufLogo, backgroundColor: "#ffffff" },
    { name: "Smit", label: "СМиТ", isLocked: false, logo: smitLogo, backgroundColor: "rgba(56, 65, 132, 1)" },
  ];

  return (
    <section className="card-section">
      {manufacturers.map((manufacturer) => (
        <div className="card-container" key={manufacturer.name}>
          <SelectableCard
            image={manufacturer.logo}
            altText={manufacturer.name}
            isSelected={selectedManufacturer === manufacturer.name}
            isLocked={manufacturer.isLocked}
            onSelect={() => handleSelect(manufacturer.name)}
            backgroundColor={manufacturer.backgroundColor}
          />
        </div>
      ))}
    </section>
  );
};

export default ManufacturerStep;
