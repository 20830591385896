import React from "react";
import SuppliesItem from "../components/SuppliesItem";
import '../style/App.css';

function Results({ title, items, count }) {
  const isPlasterboard = title === "Гипсокартон";

  return (
    <div className="results-block">
      <h2 className="results-block-title">
        {title} <span className="results-block-count">{count} позиций</span>
      </h2>
      <div className="supplies-list">
        {items.map((item, index) => (
          <SuppliesItem
            key={index}
            name={item.name}
            packaging={item.packaging}
            consumption={item.consumptionPerSquareMeter}
            quantity={item.quantity || item.value}
            icon={item.icon}
            unit={item.unit}
            units={item.units}
            isPlasterboard={isPlasterboard}
          />
        ))}
      </div>
    </div>
  );
}

export default Results;
