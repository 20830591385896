import React, { useState } from "react";
import lockIcon from "../img/in-progress.svg"; // иконка замка
import SeamUk from "../img/seamUK.png";
import SeamPluk from "../img/seamPLUK.png";

const SeamStep = ({ handleSeamSelection }) => {
  const [selectedSeam, setSelectedSeam] = useState(null);

  const handleSelect = (seam) => {
    setSelectedSeam(seam);
    handleSeamSelection(seam);
    // console.log(`Вы выбрали: ${seam}`);
  };

  return (
    <section className="card-section">
      {/* Карточка для Шов УК */}
      <div className="card-container">
        <div
          className={`selectable-card seam-card ${
            selectedSeam === "Шов УК" ? "selected" : ""
          }`}
          onClick={() => handleSelect("Шов УК")}
          style={{ backgroundColor: "rgba(206, 206, 207, 0.03)" }}
        >
          <img src={SeamUk} alt="Seam 1" className="seam-img" />
          {false && ( // Здесь замените false на условие блокировки
            <>
              <div className="lock-overlay">
                <img src={lockIcon} alt="Lock" className="lock-icon" />
              </div>
              <div className="lock-text">В работе</div>
            </>
          )}
          <div className="seam-description">Шов УК</div>
        </div>
      </div>

      {/* Карточка для Шов ПЛУК */}
      <div className="card-container">
        <div
          className={`selectable-card seam-card ${
            selectedSeam === "Шов ПЛУК" ? "selected" : ""
          }`}
          onClick={() => handleSelect("Шов ПЛУК")}
          style={{ backgroundColor: "rgba(206, 206, 207, 0.03)" }}
        >
          <img src={SeamPluk} alt="Seam 2" className="seam-img" />
          {false && ( // Здесь замените false на условие блокировки
            <>
              <div className="lock-overlay">
                <img src={lockIcon} alt="Lock" className="lock-icon" />
              </div>
              <div className="lock-text">В работе</div>
            </>
          )}
          <div className="seam-description">Шов ПЛУК</div>
        </div>
      </div>
    </section>
  );
};

export default SeamStep;
