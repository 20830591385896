import React, { useState } from "react";
import { motion } from "framer-motion";

const FreeTrialCard = () => {
  const [isVisible, setIsVisible] = useState(true);

  const closeCard = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <motion.div
          className="free-trial-card"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.3 }}
        >
          <div className="free-trial-header">
            <div className="free-trial-label">
              <div className="free-trial-icon">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.60841 16.5367V15.4733C5.50008 14.1992 3.76758 11.715 3.76758 9.075C3.76758 4.5375 7.93841 0.980831 12.6501 2.0075C14.7217 2.46583 16.5367 3.84083 17.4809 5.73833C19.3967 9.58833 17.3801 13.6767 14.4192 15.4642V16.5275C14.4192 16.7933 14.5201 17.4075 13.5392 17.4075H8.48841C7.48008 17.4167 7.60841 17.0225 7.60841 16.5367Z"
                    stroke="#F2F2F7"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.79175 20.1666C9.89091 19.5708 12.1092 19.5708 14.2084 20.1666"
                    stroke="#F2F2F7"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h4>Предупреждение</h4>
            </div>
            <button className="free-trial-close-button" onClick={closeCard}>
              ×
            </button>
          </div>
          <p>
            Данная платформа является платной, дается бесплатно 3 расчета. На
            данный момент программа в тестовом режиме. Цена может меняться.

            Активация промокода дает 3 месяца бесплатного использования.
          </p>
        </motion.div>
      )}
    </>
  );
};

export default FreeTrialCard;
