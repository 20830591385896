export const knaufWallMaterial = [
  {
    id: "30006",
    name: "Q3 КНАУФ-Тифенгрунд",
    packaging: 10,
    consumptionPerSquareMeter: 0.15,
    unit: "кг",
    icon: "products/knauf/knauf_tifergraund.jpg",
    category: "material",
    formula: "x = 0.15 * (m2)"
  },
  {
    id: "300124",
    name: "Q3 КНАУФ Ротбанд Финиш",
    packaging: 25,
    consumptionPerSquareMeter: 1.2,
    unit: "кг",
    icon: "products/knauf/knauf_rotbandfinish.png",
    category: "material",
    formula: "x = 1.2 * (m2)"
  },
  {
    id: "30006",
    name: "Q4 КНАУФ-Тифенгрунд",
    packaging: 10,
    consumptionPerSquareMeter: 0.15,
    unit: "кг",
    icon: "products/knauf/knauf_tifergraund.jpg",
    category: "material",
    formula: "x = 0.15 * (m2)"
  },
  {
    id: "300124",
    name: "Q4 КНАУФ Ротбанд Финиш",
    packaging: 25,
    consumptionPerSquareMeter: 1.2,
    unit: "кг",
    icon: "products/knauf/knauf_rotbandfinish.png",
    category: "material",
    formula: "x = 1.2 * (m2)"
  },
  {
    id: "306124",
    name: "Q4 Ротбанд Паста Профи",
    packaging: 5,
    consumptionPerSquareMeter: 1,
    unit: "кг",
    icon: "products/knauf/knauf_rotband_profi.jpg",
    category: "material",
    formula: "x = 1 * (m2)"
  },
];

export const knaufPlasterboard = [
  {
    id: "30006",
    name: "Q1 КНАУФ-Тифенгрунд",
    packaging: 10,
    consumptionPerSquareMeter: 0.05,
    unit: "кг",
    icon: "products/knauf/knauf_tifergraund.jpg",
    category: "material",
    formula: "x = 0.05 * (m2)"
  },
  {
    id: "300067",
    name: "Q1 Армирующая бумажная перфорированная лента",
    packaging: 150,
    consumptionPerSquareMeter: 1.2,
    unit: "м",
    icon: "products/knauf/knauf_lenta_bumag.jpg",
    category: "material",
    formula: "x = 1.2 * (m2)"
  },
  {
    id: "300066",
    name: "Q1 Армирующая лента Курт (альтернатива перфорированной)",
    packaging: 75,
    consumptionPerSquareMeter: 1.2,
    unit: "м",
    icon: "products/knauf/knauf_lenta_krut.jpg",
    category: "material",
    formula: "x = 1.2 * (m2)"
  },
  {
    id: "30012",
    name: "Q1 КНАУФ-Фуген",
    packaging: 10,
    consumptionPerSquareMeter: 0.25,
    unit: "кг",
    icon: "products/knauf/knauf_fugen.png",
    category: "material",
    formula: "x = 0.25 * (m2)"
  },
  {
    id: "300123",
    name: "Q1 КНАУФ-Унихард (альтернатива Фугену)",
    packaging: 20,
    consumptionPerSquareMeter: 0.25,
    unit: "кг",
    icon: "products/knauf/knauf_unihard.png",
    category: "material",
    formula: "x = 0.25 * (m2)"
  },
  {
    id: "30006",
    name: "Q2 КНАУФ-Тифенгрунд",
    packaging: 10,
    consumptionPerSquareMeter: 0.05,
    unit: "кг",
    icon: "products/knauf/knauf_tifergraund.jpg",
    category: "material",
    formula: "x = 0.05 * (m2)"
  },
  {
    id: "30012",
    name: "Q2 КНАУФ-Фуген",
    packaging: 10,
    consumptionPerSquareMeter: 1,
    unit: "кг",
    icon: "products/knauf/knauf_fugen.png",
    category: "material",
    formula: "x = 1 * (m2)"
  },
  {
    id: "300123",
    name: "Q2 КНАУФ-Унихард (альтернатива Фугену)",
    packaging: 20,
    consumptionPerSquareMeter: 1,
    unit: "кг",
    icon: "products/knauf/knauf_unihard.png",
    category: "material",
    formula: "x = 1 * (m2)"
  },
  {
    id: "30006",
    name: "Q3 КНАУФ-Тифенгрунд",
    packaging: 10,
    consumptionPerSquareMeter: 0.15,
    unit: "кг",
    icon: "products/knauf/knauf_tifergraund.jpg",
    category: "material",
    formula: "x = 0.15 * (m2)"
  },
  {
    id: "30012",
    name: "Q3 КНАУФ-Фуген",
    packaging: 10,
    consumptionPerSquareMeter: 1,
    unit: "кг",
    icon: "products/knauf/knauf_fugen.png",
    category: "material",
    formula: "x = 1 * (m2)"
  },
  {
    id: "300123",
    name: "Q3 КНАУФ-Унихард (альтернатива Фугену)",
    packaging: 20,
    consumptionPerSquareMeter: 1,
    unit: "кг",
    icon: "products/knauf/knauf_unihard.png",
    category: "material",
    formula: "x = 1 * (m2)"
  },
  {
    id: "300124",
    name: "Q3 КНАУФ Ротбанд Финиш",
    packaging: 25,
    consumptionPerSquareMeter: 1.2,
    unit: "кг",
    icon: "products/knauf/knauf_rotbandfinish.png",
    category: "material",
    formula: "x = 1.2 * (m2)"
  },
  {
    id: "30006",
    name: "Q4 КНАУФ-Тифенгрунд",
    packaging: 10,
    consumptionPerSquareMeter: 0.15,
    unit: "кг",
    icon: "products/knauf/knauf_tifergraund.jpg",
    category: "material",
    formula: "x = 0.15 * (m2)"
  },
  {
    id: "30012",
    name: "Q4 КНАУФ-Фуген",
    packaging: 10,
    consumptionPerSquareMeter: 1,
    unit: "кг",
    icon: "products/knauf/knauf_fugen.png",
    category: "material",
    formula: "x = 1 * (m2)"
  },
  {
    id: "300123",
    name: "Q4 КНАУФ-Унихард (альтернатива Фугену)",
    packaging: 20,
    consumptionPerSquareMeter: 1,
    unit: "кг",
    icon: "products/knauf/knauf_unihard.png",
    category: "material",
    formula: "x = 1 * (m2)"
  },
  {
    id: "300124",
    name: "Q4 КНАУФ Ротбанд Финиш",
    packaging: 25,
    consumptionPerSquareMeter: 1.2,
    unit: "кг",
    icon: "products/knauf/knauf_rotbandfinish.png",
    category: "material",
    formula: "x = 1.2 * (m2)"
  },
  {
    id: "306124",
    name: "Q4 Ротбанд Паста Профи",
    packaging: 5,
    consumptionPerSquareMeter: 1,
    unit: "кг",
    icon: "products/knauf/knauf_rotband_profi.jpg",
    category: "material",
    formula: "x = 1 * (m2)"
  },
]
