import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import NewCalculation from './NewCalculation';
import MyPrices from './MyPrices'; // Импортируем страницу "Мои цены"
import PrivateRoute from '../Routes/PrivateRoute'; // Защищенный маршрут
import PublicRoute from '../Routes/PublicRoute'; // Публичный маршрут
import '../style/App.css';
import { AuthProvider } from '../contexts/AuthContext';

const ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  NEW_CALCULATION: '/new-calculation',
  MY_PRICES: '/my-prices', // Добавляем маршрут для "Мои цены"
  DEFAULT: '/new-calculation',
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            {/* Публичный маршрут для входа */}
            <Route
              path={ROUTES.LOGIN}
              element={
                <PublicRoute restricted={true}>
                  <Login />
                </PublicRoute>
              }
            />

            {/* Публичный маршрут для регистрации */}
            <Route
              path={ROUTES.REGISTER}
              element={
                <PublicRoute restricted={true}>
                  <Register />
                </PublicRoute>
              }
            />

            {/* Приватный маршрут для нового расчета */}
            <Route
              path={ROUTES.NEW_CALCULATION}
              element={
                <PrivateRoute>
                  <NewCalculation />
                </PrivateRoute>
              }
            />

            {/* Приватный маршрут для страницы "Мои цены" */}
            <Route
              path={ROUTES.MY_PRICES}
              element={
                <PrivateRoute>
                  <MyPrices />
                </PrivateRoute>
              }
            />

            {/* Перенаправление на дефолтный маршрут */}
            <Route path="*" element={<Navigate to={ROUTES.DEFAULT} />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
